import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setCmdPaletteOpen, setCmdPaletteSubMenuAction } from 'shared/foreground/cmdPalette';
import { messageCopy } from 'shared/foreground/messageCopy';
import { globalState } from 'shared/foreground/models';
import background from 'shared/foreground/portalGates/toBackground';
import { usePartialDocument, useRssFeeds } from 'shared/foreground/stateHooks';
import useRSSFeedIdByUrl from 'shared/foreground/stateHooks/useRSSFeedIdByUrl';
import {
  addFeed,
  removeFeed,
  toggleDocumentFeedSubscription,
  updateFeedDescription,
  updateFeedTitle,
} from 'shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import { createToast } from 'shared/foreground/toasts.platform';
import { useMatchingRSS } from 'shared/foreground/useMatchingRss';
import useGlobalStateWithFallback from 'shared/foreground/utils/useGlobalStateWithFallback';
import type { DocumentId, PersistentState, RssFeed, RSSSuggestion } from 'shared/types';
import { MainTitleType, RSSFeedPopularityDisplay } from 'shared/types';
import getFormattedDurationFromNow from 'shared/utils/dates/getFormattedDurationFromNow';
import { isValidRssUrl } from 'shared/utils/isValidRssUrl';
import useDebounce from 'shared/utils/useDebounce';

import { isFeedsSourcesList } from '../../utils/pathnameHelpers';
import useLocation from '../../utils/useLocation';
import FeedFallbackFavicon from '../icons/FeedFallbackFavicon';
import MinusIcon from '../icons/MinusIcon';
import PlusInCircleIcon from '../icons/PlusInCircleIcon';
import { ImageWithFallback } from '../ImageWithFallback';
import { PaletteAction, PaletteGroup } from './Base/PaletteAction';
import { CmdInputContext, PaletteWrapper } from './Base/PaletteWrapper';
import feedStyles from './FeedsPalette.module.css';

function getAllIndexes(sentence: string, substring: string) {
  // returns all indexes at which `substring` appears in `sentence`
  const indexes = [];
  let end = 0;
  if (!substring) {
    return [];
  }
  for (let i = 0; i < sentence.length; i++) {
    if (sentence.slice(end, end + substring.length).toLowerCase() === substring.toLowerCase()) {
      indexes.push(end);
      end = i + substring.length;
    } else {
      end = i;
    }
  }
  return indexes;
}

function showQueryInResult(sentence: string, query: string) {
  // wraps each `query` substring in `sentence` with <em> tag, returns an Array of elements
  const matchindexes = getAllIndexes(sentence, query);
  if (!matchindexes) {
    return [sentence];
  }
  let currentEnd = 0;
  const parts = [];
  for (const ind of matchindexes) {
    parts.push(sentence.slice(currentEnd, ind));
    parts.push(
      <em className={feedStyles.feedQueryPart} key={`${ind}-query`}>
        {sentence.slice(ind, ind + query.length)}
      </em>,
    );
    currentEnd = ind + query.length;
  }
  parts.push(sentence.slice(currentEnd));
  return parts;
}

const AddNewFeedAction = ({
  focused,
  rssFeeds,
  fillSuggestions,
}: PaletteAction & {
  rssFeeds: PersistentState['rssFeeds'] | null;
  fillSuggestions: (s: RSSSuggestion[], q: string) => void;
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { input } = useContext(CmdInputContext);

  const debouncedInput = useDebounce(input, 500);

  const loadNewSuggestions = useCallback(
    async (input: string, signal: AbortSignal) => {
      try {
        const response = await background.getRssSuggestions(input, signal);
        fillSuggestions(response, input);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (error.name === 'AbortError') {
          // input changed before receiving a response
          return;
        }
        throw error;
      }
    },
    [fillSuggestions],
  );

  useEffect(() => {
    const controller = new AbortController();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (debouncedInput && debouncedInput.length > 1) {
      loadNewSuggestions(debouncedInput, controller.signal);
    } else {
      fillSuggestions([], debouncedInput);
    }

    return () => controller.abort();
  }, [debouncedInput, fillSuggestions, loadNewSuggestions]);

  const action = useMemo(
    () => async () => {
      if (input === '') {
        return;
      }

      if (!isValidRssUrl(input)) {
        createToast({
          content: `Please, use a valid URL`,
          category: 'error',
        });
        return;
      }
      // Check if URL is a twitter list:
      // All twitter lists start with https://twitter.com/i/lists
      // If URL starts with this, change the toast content.

      let toast = '';
      if (input.includes('twitter.com/i/lists/')) {
        toast = 'Subscribed to Twitter list';
      }

      await addFeed({ url: input }, { userInteraction: 'unknown', toastContent: toast });
      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });

      if (!isFeedsSourcesList(pathname)) {
        history.push({
          pathname: '/feed',
          search: '?shouldSelectLatestFeedDoc=true',
        });
      }
    },
    [input, history, pathname],
  );

  const childClass = useMemo(() => input ? '' : feedStyles.emptyQuery, [input]);

  if (rssFeeds && input.toLowerCase() in rssFeeds) {
    return null;
  }

  return (
    <PaletteAction focused={focused} action={action} childrenClassName={childClass}>
      <span className={feedStyles.rssFeedItemWrapper}>
        <PlusInCircleIcon className={`${feedStyles.addIcon} ${focused ? feedStyles.focused : ''}`} />
        <span className={feedStyles.feedDisplayItem}>{input}</span>
      </span>
    </PaletteAction>
  );
};

const feedItemDisplay = (feedData: RSSSuggestion | RssFeed, query: string) => {
  if (!feedData.name) {
    return <>{...showQueryInResult(feedData.url, query)}</>;
  }
  return (
    <>
      <ImageWithFallback
        className={feedStyles.rssFeedIcon}
        imageUrl={feedData.image_url}
        fallbackImage={<FeedFallbackFavicon className={feedStyles.rssFeedIcon} />}
      />
      <span className={feedStyles.feedDisplayItemName}>
        {...showQueryInResult(feedData.name, query)}
      </span>
      {feedData.description
        ? <>
          {' '}
          <span className={feedStyles.feedDisplayItemDescription} title={feedData.description}>
            {...showQueryInResult(feedData.description, query)}
          </span>
        </>
       : null}
      <span className={feedStyles.feedDisplayItemDetails}>
        {feedData?.popularity ? <span>{RSSFeedPopularityDisplay[feedData.popularity]}</span> : null}
        {feedData?.last_entry_added_at
          ? <span>Updated {getFormattedDurationFromNow(feedData.last_entry_added_at)}</span>
         : null}
        <span>{...showQueryInResult(feedData.url, query)}</span>
      </span>
    </>
  );
};

const AddSuggestedFeedAction = ({
  focused,
  feedData,
  query,
}: PaletteAction & { feedData: RSSSuggestion; query: string; }) => {
  const history = useHistory();
  const action = useMemo(
    () => async () => {
      await addFeed(feedData, { userInteraction: 'unknown' });
      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
      history.push({
        pathname: '/feed',
        search: '?shouldSelectLatestFeedDoc=true',
      });
    },
    [history, feedData],
  );

  const display = useMemo(() => feedItemDisplay(feedData, query), [feedData, query]);
  return (
    <PaletteAction
      focused={focused}
      action={action}
      childrenClassName={feedStyles.overflowHidden}
      childrenTitle={feedData.url}
    >
      <span className={feedStyles.rssFeedItemWrapper}>
        <PlusInCircleIcon className={`${feedStyles.addIcon} ${focused ? feedStyles.focused : ''}`} />
        <span className={feedStyles.feedDisplayItem}>{display}</span>
      </span>
    </PaletteAction>
  );
};

const DeleteFeedAction = React.memo(function DeleteFeedAction({
  focused,
  id,
  feedData,
  query,
}: PaletteAction & { id: string; feedData: RssFeed; query: string; }) {
  const action = async () => {
    // eslint-disable-next-line no-alert
    const shouldContinue = confirm(`Warning: ${messageCopy.removeFeedWarningText} Continue?`);

    if (!shouldContinue) {
      return;
    }

    await removeFeed(id, { userInteraction: 'unknown' });
    await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
  };

  const display = useMemo(() => feedItemDisplay(feedData, query), [feedData, query]);

  return (
    <PaletteAction
      focused={focused}
      action={action}
      childrenClassName={feedStyles.overflowHidden}
      childrenTitle={feedData.url}
    >
      <span className={feedStyles.rssFeedItemWrapper}>
        <MinusIcon className={`${feedStyles.removeIcon} ${focused ? feedStyles.focused : ''}`} />
        <span className={feedStyles.feedDisplayItem}>{display}</span>
      </span>
    </PaletteAction>
  );
});

export const ManageFeedSubscriptionFeedAction = React.memo(function ManageFeedSubscriptionFeedAction({
  focused,
  docId,
  shortcut,
}: PaletteAction & { docId: DocumentId; }) {
  const [existingDoc] = usePartialDocument(docId, ['url', 'category', 'source_specific_data']);
  const { possibleRss, subscribed } = useMatchingRSS(existingDoc);
  const feedId = useRSSFeedIdByUrl(possibleRss?.url);
  const action = useMemo(
    () => async () =>
      toggleDocumentFeedSubscription(
        possibleRss,
        subscribed,
        feedId,
        messageCopy.removeFeedWarningText,
        { userInteraction: 'unknown' },
      ),
    [feedId, possibleRss, subscribed],
  );
  const commandCopy = useMemo(
    () => subscribed ? 'Unsubscribe from feed' : 'Subscribe to feed',
    [subscribed],
  );

  return possibleRss
    ? <PaletteAction focused={focused} action={action} shortcut={shortcut}>
      {commandCopy}
    </PaletteAction>
   : null;
});

export const FeedsPalette = (): JSX.Element => {
  const rssFeeds = useRssFeeds();
  const [suggestions, setSuggestions] = useState<RSSSuggestion[]>([]);
  const [query, setQuery] = useState<string>('');

  const onSuggestionsLoaded = useCallback(
    (rssSuggestions: RSSSuggestion[], searchTerm: string) => {
      const knownFeeds = Object.values(rssFeeds).map((f) => f.name);
      setSuggestions(rssSuggestions.filter((feed) => !knownFeeds.includes(feed.name)));
      setQuery(searchTerm);
    },
    [rssFeeds],
  );

  return (
    <PaletteWrapper title="Add Feeds" placeholder="Start typing the name or URL of your RSS feed">
      <PaletteGroup title="Feeds">
        {suggestions.map((feed, i) =>
          <AddSuggestedFeedAction
            key={`${feed.id}-suggestion`}
            focused={false}
            feedData={feed}
            query={query}
            uniqueId={`${feed.id}-suggestion`}
            mainTitleType={MainTitleType.Rss}
          />)}
        <AddNewFeedAction
          key="new-feed"
          focused={false}
          rssFeeds={rssFeeds}
          fillSuggestions={onSuggestionsLoaded}
          mainTitleType={MainTitleType.Rss}
        />
        {Object.keys(rssFeeds).map((id) => {
          const feed = rssFeeds[id];
          const label = `${feed.name} ${feed.url} ${feed.description}`;
          return (
            <DeleteFeedAction
              key={id}
              focused={false}
              id={id}
              feedData={rssFeeds[id]}
              label={label}
              uniqueId={`${id}-delete`}
              mainTitleType={MainTitleType.Rss}
              query={query}
            />
          );
        })}
      </PaletteGroup>
    </PaletteWrapper>
  );
};

const EditFeedNameAction = ({ focused }: PaletteAction) => {
  const { input, setInput } = useContext(CmdInputContext);
  const rssFeeds = useGlobalStateWithFallback(
    {},
    useCallback((state) => state.persistent.rssFeeds, []),
  );
  const focusedFeedId = globalState(useCallback((state) => state.focusedFeedId || '', []));
  const feedName = rssFeeds[focusedFeedId]?.name;

  useEffect(() => {
    setInput(feedName || '');
  }, [setInput, feedName]);

  const action = useMemo(
    () => async () => {
      if (input === '') {
        return;
      }

      updateFeedTitle(focusedFeedId, input, { userInteraction: 'enter' });
      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [input, focusedFeedId],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      Save changes
    </PaletteAction>
  );
};

const EditFeedDescriptionAction = ({ focused }: PaletteAction) => {
  const { input, setInput } = useContext(CmdInputContext);
  const rssFeeds = useGlobalStateWithFallback(
    {},
    useCallback((state) => state.persistent.rssFeeds, []),
  );
  const focusedFeedId = globalState(useCallback((state) => state.focusedFeedId || '', []));
  const feedDescription = rssFeeds[focusedFeedId]?.description || '';

  useEffect(() => {
    setInput(feedDescription || '');
  }, [setInput, feedDescription]);

  const action = useMemo(
    () => async () => {
      if (input === '') {
        return;
      }

      updateFeedDescription(focusedFeedId, input, { userInteraction: 'enter' });
      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [input, focusedFeedId],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      Save changes
    </PaletteAction>
  );
};

const OpenEditFeedNameAction = ({
  focused,
  setCurrentAction,
}: PaletteAction & { setCurrentAction: (action: string) => void; }) => {
  const action = useMemo(
    () => async () => {
      setCurrentAction('edit-feed-name');
    },
    [setCurrentAction],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      Edit feed name
    </PaletteAction>
  );
};

const OpenEditFeedDescriptionAction = ({
  focused,
  setCurrentAction,
}: PaletteAction & { setCurrentAction: (action: string) => void; }) => {
  const action = useMemo(
    () => async () => {
      setCurrentAction('edit-feed-description');
    },
    [setCurrentAction],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      Edit feed description
    </PaletteAction>
  );
};

export const EditFeedPalette = (): JSX.Element => {
  const currentAction = globalState(useCallback((state) => state.cmdPalette.subMenuAction, []));
  const setCurrentAction = (subMenuAction: string) => {
    setCmdPaletteSubMenuAction(subMenuAction, { userInteraction: 'unknown' });
  };

  return (
    <PaletteWrapper title="Edit Feed" placeholder="Type a command">
      <PaletteGroup title="">
        {!currentAction &&
          <OpenEditFeedNameAction
            label="Edit feed name"
            focused={false}
            setCurrentAction={setCurrentAction}
            mainTitleType={MainTitleType.EditFeed}
          />
        }
        {!currentAction &&
          <OpenEditFeedDescriptionAction
            label="Edit feed description"
            focused={false}
            setCurrentAction={setCurrentAction}
            mainTitleType={MainTitleType.EditFeed}
          />
        }
        {currentAction === 'edit-feed-name' &&
          <EditFeedNameAction focused={false} mainTitleType={MainTitleType.EditFeed} />
        }
        {currentAction === 'edit-feed-description' &&
          <EditFeedDescriptionAction focused={false} mainTitleType={MainTitleType.EditFeed} />
        }
      </PaletteGroup>
    </PaletteWrapper>
  );
};
