import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { setCmdPaletteOpen, setCmdPaletteSubMenuAction } from 'shared/foreground/cmdPalette';
import database from 'shared/foreground/database';
import { globalState, SplitByKeyDisplayName } from 'shared/foreground/models';
import { useGlobalTagsAsObject, useSavedFilteredViews } from 'shared/foreground/stateHooks';
import {
  saveFilteredView,
  updateFilteredView,
} from 'shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import { queueJob } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/jobs';
import { setFilterQueryToCreate } from 'shared/foreground/stateUpdaters/transientStateUpdaters/filtereredView';
import { createToast } from 'shared/foreground/toasts.platform';
import useCurrentFilteredViewFromQuery, {
  useCurrentFilteredViewFromId,
} from 'shared/foreground/useCurrentFilteredView';
import useFilteredViewMangoQuery from 'shared/foreground/useFilteredViewMangoQuery';
import getSplitByDefaultValue from 'shared/foreground/utils/getSplitByDefaultValue';
import useDocumentLocations from 'shared/foreground/utils/useDocumentLocations';
import type { FilteredView } from 'shared/types';
import { JobType, MainTitleType, SplitByKey } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import { safeDecodeURIComponent } from 'shared/utils/safeDecodeURIComponent';
import urlJoin from 'shared/utils/urlJoin';

import fixedEncodeURIComponent from '../../utils/fixedEncodeURIComponent';
import { getFilteredViewPath } from '../../utils/getFilteredViewPath';
import { isValidQuery } from '../../utils/isValidQuery';
import { openURL } from '../../utils/openURL';
import {
  getFilterViewQueryFromPathname,
  isFeedsSourcesList,
  isTagsList,
} from '../../utils/pathnameHelpers';
import { useShortcutsMap } from '../../utils/shortcuts';
import useLocation from '../../utils/useLocation';
import { PaletteAction, PaletteGroup } from './Base/PaletteAction';
import { CmdInputContext, PaletteWrapper } from './Base/PaletteWrapper';

const FilterAllDocs = ({
  focused,
  fillInputWithQuery = false,
}: PaletteAction & { fillInputWithQuery?: boolean; }) => {
  const history = useHistory();
  const { input, setInput } = useContext(CmdInputContext);
  const pathname = history.location.pathname.split('/split')[0];
  const filterPagePathname = '/filter/';
  const isFiltersPage = pathname.startsWith(filterPagePathname);

  useEffect(() => {
    if (!isFiltersPage || !fillInputWithQuery) {
      return;
    }

    const query = pathname.replace(filterPagePathname, '');
    const decodedQuery = safeDecodeURIComponent(query);

    if (decodedQuery) {
      setInput(decodedQuery);
    }
  }, [isFiltersPage, setInput, pathname, fillInputWithQuery]);

  const action = useMemo(
    () => async () => {
      if (input === '') {
        return;
      }

      history.push(urlJoin(['/filter', fixedEncodeURIComponent(input)]));
      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [input, history],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      Filter by query
    </PaletteAction>
  );
};

const LaunchFilteringGuideAction = ({ focused }: PaletteAction) => {
  const action = useMemo(
    () => async () => {
      openURL('https://docs.readwise.io/reader/guides/filtering/syntax-guide', '_blank');
    },
    [],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      Launch filtering guide
    </PaletteAction>
  );
};

export const FilterAllDocsPalette = ({
  fillInputWithQuery = false,
}: { fillInputWithQuery?: boolean; }): JSX.Element => {
  return (
    <PaletteWrapper title="Filter all docs" placeholder="Start typing your query...">
      <FilterAllDocs key="new-filter" focused={false} fillInputWithQuery={fillInputWithQuery} />
      <GoToViewsPageAction focused={false} />
      <LaunchFilteringGuideAction uniqueId="launch-filtering-guid" key="launch-guide" focused={false} />
    </PaletteWrapper>
  );
};

// This panel will prompt from the views dropdown in feeds management page
const SaveFeedView = ({ focused }: PaletteAction) => {
  const shortcutsMap = useShortcutsMap();
  const { input } = useContext(CmdInputContext);
  const focusedFeedId = globalState(useCallback((state) => state.focusedFeedId, []));
  const filterQueryToCreate = globalState(useCallback((state) => state.filterQueryToCreate, []));

  const action = useMemo(
    () => async () => {
      if (input === '') {
        createToast({
          content: 'Please, enter the view name',
          category: 'error',
        });
        return;
      }

      const query = filterQueryToCreate ?? `rssSource:"${focusedFeedId}"`;
      const filter = {
        name: input,
        query,
        splitBy: SplitByKey.Seen,
      };

      setFilterQueryToCreate(null);
      saveFilteredView(filter, window.location.pathname, { userInteraction: 'keypress' });
      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [focusedFeedId, input, filterQueryToCreate],
  );

  return (
    <PaletteAction focused={focused} action={action} shortcut={shortcutsMap[ShortcutId.Enter]}>
      Save feed filtered view
    </PaletteAction>
  );
};

// This panel will prompt from the views dropdown in tags management page
const SaveTagView = ({ focused }: PaletteAction) => {
  const { input, setInput } = useContext(CmdInputContext);
  const focusedTagId = globalState(useCallback((state) => state.focusedTagId, []));
  const [globalTagsObject] = useGlobalTagsAsObject();
  const focusedTag = focusedTagId ? globalTagsObject[focusedTagId] : undefined;
  const filterQueryToCreate = globalState(useCallback((state) => state.filterQueryToCreate, []));
  const shortcutsMap = useShortcutsMap();

  useEffect(() => {
    if (!focusedTag) {
      return;
    }

    setInput(focusedTag.name);
  }, [focusedTag, setInput]);

  const action = useMemo(
    () => async () => {
      if (!focusedTag) {
        return;
      }

      if (input === '') {
        createToast({
          content: 'Please, enter the view name',
          category: 'error',
        });
        return;
      }

      const query = filterQueryToCreate ?? `tag:"${focusedTag.name}"`;
      const filter = {
        name: input,
        query,
        splitBy: SplitByKey.Seen,
      };

      setFilterQueryToCreate(null);
      saveFilteredView(filter, window.location.pathname, { userInteraction: 'keypress' });
      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [focusedTag, input, filterQueryToCreate],
  );

  return (
    <PaletteAction focused={focused} action={action} shortcut={shortcutsMap[ShortcutId.Enter]}>
      Save tag filtered view
    </PaletteAction>
  );
};

const SaveNewFilter = ({ focused }: PaletteAction) => {
  const history = useHistory();
  const { input } = useContext(CmdInputContext);
  const pathname = history.location.pathname.split('/split')[0];
  const filterPagePathname = '/filter/';
  const query = pathname.replace(filterPagePathname, '');
  const decodedQuery = safeDecodeURIComponent(query);
  const shortcutsMap = useShortcutsMap();

  const action = useMemo(
    () => async () => {
      if (!decodedQuery) {
        createToast({
          content: 'Please, write a query first',
          category: 'error',
        });
        return;
      }

      if (input === '') {
        createToast({
          content: 'Please, enter the view name',
          category: 'error',
        });
        return;
      }

      const filter = {
        name: input,
        query: decodedQuery,
      };

      saveFilteredView(filter, window.location.pathname, { userInteraction: 'keypress' });
      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [input, decodedQuery],
  );

  return (
    <PaletteAction focused={focused} action={action} shortcut={shortcutsMap[ShortcutId.Enter]}>
      Save current filtered view
    </PaletteAction>
  );
};

const SaveFilterAction = ({ focused }: PaletteAction) => {
  const { pathname } = useLocation();
  const focusedFeedId = globalState(useCallback((state) => state.focusedFeedId, []));
  const focusedTagId = globalState(useCallback((state) => state.focusedTagId, []));
  const isFeedSourcesList = isFeedsSourcesList(pathname);
  const isTags = isTagsList(pathname);
  const query = getFilterViewQueryFromPathname(pathname);
  const isValidView = useMemo(() => isValidQuery(query || ''), [query]);

  if (focusedFeedId && isFeedSourcesList) {
    return <SaveFeedView focused={focused} />;
  }

  if (focusedTagId && isTags) {
    return <SaveTagView focused={focused} />;
  }

  if (isValidView) {
    return <SaveNewFilter focused={focused} />;
  } else {
    setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    createToast({
      content: `Error, invalid view cannot be saved.`,
      category: 'error',
    });
    return null;
  }
};

export const SaveFilterPalette = (): JSX.Element => {
  return (
    <PaletteWrapper title="Save current filtered view" placeholder="View name">
      <SaveFilterAction key="save-filter" focused={false} />
    </PaletteWrapper>
  );
};

export const GoToViewsPageAction = ({ focused }: PaletteAction) => {
  const history = useHistory();
  const shortcutsMap = useShortcutsMap();

  const action = useMemo(
    () => async () => {
      history.push('/views');
      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [history],
  );

  return (
    <PaletteAction
      focused={focused}
      action={action}
      shortcut={shortcutsMap[ShortcutId.OpenFilteredViews]}
    >
      Manage saved filtered views
    </PaletteAction>
  );
};

const ViewFilterAction = ({
  focused,
  view,
  shortcut,
}: PaletteAction & { view: FilteredView; shortcut?: string | string[]; }) => {
  const history = useHistory();
  const documentLocations = useDocumentLocations();

  const action = useMemo(
    () => async () => {
      const url = getFilteredViewPath(view, documentLocations);
      history.push(url);
      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [documentLocations, history, view],
  );

  return (
    <PaletteAction focused={focused} shortcut={shortcut} action={action}>
      {view.name}
    </PaletteAction>
  );
};

export const ViewFiltersPalette = (): JSX.Element => {
  const filteredViews = useSavedFilteredViews();
  const shortcutsMap = useShortcutsMap();
  const hasSavedFilteredViews = filteredViews.length > 0;

  return (
    <PaletteWrapper title="Browse filtered views" placeholder="Search view">
      {filteredViews.map((view, index) => {
        const shortcutNumber = index + 4;
        const showShortcut = !view.isUnpinned && shortcutNumber < 10;
        const shortcut = showShortcut ? shortcutsMap[ShortcutId[`GoToPinnedView${index + 1}` as keyof typeof ShortcutId]] : '';

        return (
          <ViewFilterAction
            key={`view-filter-${view.id}`}
            label={view.name}
            view={view}
            focused={false}
            shortcut={shortcut}
          />
        );
      })}
      {!hasSavedFilteredViews && <PaletteAction focused={false}>No filtered views</PaletteAction>}
      <GoToViewsPageAction focused={false} />
    </PaletteWrapper>
  );
};

const SplitByAction = ({
  focused,
  splitBy,
  savedFilter,
  query,
}: PaletteAction & { splitBy: SplitByKey; query: string; savedFilter?: FilteredView; }) => {
  const history = useHistory();
  const documentLocations = useDocumentLocations();

  const action = useMemo(
    () => async () => {
      const splitByDefaultValue = getSplitByDefaultValue(splitBy, documentLocations);

      if (savedFilter) {
        updateFilteredView(
          {
            ...savedFilter,
            splitBy,
          },
          { userInteraction: 'keypress' },
        );
        history.push(
          urlJoin([
            '/filter',
            encodeURIComponent(savedFilter.query),
            'split',
            splitBy,
            splitByDefaultValue,
          ]),
        );
      } else {
        history.push(urlJoin(['/filter', query, 'split', splitBy, splitByDefaultValue]));
      }

      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [documentLocations, history, query, splitBy, savedFilter],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      {SplitByKeyDisplayName[splitBy]}
    </PaletteAction>
  );
};

const RemoveSplitByAction = ({
  focused,
  savedFilter,
  query,
}: PaletteAction & { query: string; savedFilter?: FilteredView; }) => {
  const history = useHistory();

  const action = useMemo(
    () => async () => {
      if (savedFilter) {
        updateFilteredView(
          {
            ...savedFilter,
            splitBy: undefined,
          },
          { userInteraction: 'keypress' },
        );
        history.push(urlJoin(['/filter', encodeURIComponent(savedFilter.query)]));
      } else {
        history.push(urlJoin(['/filter', query]));
      }

      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [history, query, savedFilter],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      None
    </PaletteAction>
  );
};

export const SplitByPalette = (): JSX.Element => {
  const history = useHistory();
  const pathname = history.location.pathname.split('/split')[0];
  const filterPagePathname = '/filter/';
  const query = safeDecodeURIComponent(pathname.replace(filterPagePathname, ''));
  const savedFilter = useCurrentFilteredViewFromQuery(query);
  return (
    <PaletteWrapper title="Split by" placeholder="Split by">
      {Object.values(SplitByKey).map((splitBy) =>
        <SplitByAction
          key={`split-by-${splitBy}`}
          label={splitBy}
          splitBy={splitBy}
          savedFilter={savedFilter}
          query={query}
          focused={false}
        />)}
      {savedFilter?.splitBy &&
        <RemoveSplitByAction label="none" savedFilter={savedFilter} query={query} focused={false} />
      }
    </PaletteWrapper>
  );
};

const OpenEditViewNameAction = ({
  focused,
  setCurrentAction,
}: PaletteAction & { setCurrentAction: (action: string) => void; }) => {
  const action = useMemo(
    () => async () => {
      setCurrentAction('edit-view-name');
    },
    [setCurrentAction],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      Edit name
    </PaletteAction>
  );
};

const OpenEditViewDescriptionAction = ({
  focused,
  setCurrentAction,
}: PaletteAction & { setCurrentAction: (action: string) => void; }) => {
  const action = useMemo(
    () => async () => {
      setCurrentAction('edit-view-description');
    },
    [setCurrentAction],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      Edit description
    </PaletteAction>
  );
};

const OpenEditViewQueryAction = ({
  focused,
  setCurrentAction,
}: PaletteAction & { setCurrentAction: (action: string) => void; }) => {
  const action = useMemo(
    () => async () => {
      setCurrentAction('edit-view-query');
    },
    [setCurrentAction],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      Edit query
    </PaletteAction>
  );
};

const EditViewNameAction = ({ focused }: PaletteAction) => {
  const { input, setInput } = useContext(CmdInputContext);
  const focusedViewId = globalState(useCallback((state) => state.focusedViewId || '', []));
  const view = useCurrentFilteredViewFromId(focusedViewId);
  const viewName = view?.name;

  useEffect(() => {
    setInput(viewName || '');
  }, [setInput, viewName]);

  const action = useMemo(
    () => async () => {
      if (input === '' || !view) {
        return;
      }

      updateFilteredView(
        {
          ...view,
          name: input,
        },
        { userInteraction: 'keypress' },
      );

      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [input, view],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      Save changes
    </PaletteAction>
  );
};

const EditViewDescriptionAction = ({ focused }: PaletteAction) => {
  const { input, setInput } = useContext(CmdInputContext);
  const focusedViewId = globalState(useCallback((state) => state.focusedViewId || '', []));
  const view = useCurrentFilteredViewFromId(focusedViewId);
  const viewDescription = view?.description;

  useEffect(() => {
    setInput(viewDescription || '');
  }, [setInput, viewDescription]);

  const action = useMemo(
    () => async () => {
      if (input === '' || !view) {
        return;
      }

      updateFilteredView(
        {
          ...view,
          description: input,
        },
        { userInteraction: 'keypress' },
      );

      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [input, view],
  );

  return (
    <PaletteAction focused={focused} action={action}>
      Save changes
    </PaletteAction>
  );
};

const EditViewQueryAction = ({ focused }: PaletteAction) => {
  const history = useHistory();
  const pathName = history.location.pathname;
  const { input, setInput } = useContext(CmdInputContext);
  const focusedViewId = globalState(useCallback((state) => state.focusedViewId || '', []));
  const view = useCurrentFilteredViewFromId(focusedViewId);
  const viewQuery = view?.query;

  useEffect(() => {
    setInput(viewQuery || '');
  }, [setInput, viewQuery]);

  const documentLocations = useDocumentLocations();
  const splitBy = view?.splitBy;

  const splitValueOrDefault = useMemo(() => {
    if (!splitBy) {
      return undefined;
    }

    return getSplitByDefaultValue(splitBy, documentLocations) ?? undefined;
  }, [documentLocations, splitBy]);

  const { mangoQuery } = useFilteredViewMangoQuery({
    view,
    splitByUrlParam: view?.splitBy,
    splitValue: splitValueOrDefault,
  });

  const action = useMemo(
    () => async () => {
      if (input === '' || !view) {
        return;
      }

      await updateFilteredView(
        {
          ...view,
          query: input,
        },
        { userInteraction: 'keypress' },
      );

      if (pathName.startsWith('/filter/')) {
        history.push(urlJoin(['/filter', fixedEncodeURIComponent(input)]));
      }

      await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    },
    [input, view, pathName, history],
  );

  useEffect(() => {
    async function updateBundleIfNeeded() {
      if (!view?.sharedAsBundle) {
        return;
      }

      const documentIds = await database.collections.documents.findIds({ ...mangoQuery, limit: 500 });

      queueJob({
        jobType: JobType.UpdateBundle,
        jobArguments: { filtered_view_id: view.id, document_ids: documentIds, enabled: true },
        options: { userInteraction: 'click' },
      });
    }

    updateBundleIfNeeded();
  }, [mangoQuery, view?.id, view?.sharedAsBundle]);

  return (
    <PaletteAction focused={focused} action={action}>
      Save changes
    </PaletteAction>
  );
};

export const EditViewPalette = (): JSX.Element => {
  const currentAction = globalState(useCallback((state) => state.cmdPalette.subMenuAction, []));
  const setCurrentAction = (subMenuAction: string) => {
    setCmdPaletteSubMenuAction(subMenuAction, { userInteraction: 'unknown' });
  };

  return (
    <PaletteWrapper title="Edit view" placeholder="Type a command...">
      <PaletteGroup title="">
        {!currentAction &&
          <OpenEditViewNameAction
            label="Edit name"
            focused={false}
            setCurrentAction={setCurrentAction}
            mainTitleType={MainTitleType.EditFilteredView}
          />
        }
        {!currentAction &&
          <OpenEditViewDescriptionAction
            label="Edit description"
            focused={false}
            setCurrentAction={setCurrentAction}
            mainTitleType={MainTitleType.EditFilteredView}
          />
        }
        {!currentAction &&
          <OpenEditViewQueryAction
            label="Edit query"
            focused={false}
            setCurrentAction={setCurrentAction}
            mainTitleType={MainTitleType.EditFilteredView}
          />
        }
        {currentAction === 'edit-view-name' &&
          <EditViewNameAction focused={false} mainTitleType={MainTitleType.EditFilteredView} />
        }
        {currentAction === 'edit-view-description' &&
          <EditViewDescriptionAction focused={false} mainTitleType={MainTitleType.EditFilteredView} />
        }
        {currentAction === 'edit-view-query' &&
          <EditViewQueryAction focused={false} mainTitleType={MainTitleType.EditFilteredView} />
        }
      </PaletteGroup>
    </PaletteWrapper>
  );
};
